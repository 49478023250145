import React from "react"
import classNames from "classnames/bind"
import styles from "./about.module.scss"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import AboutJSON from "../../content/PageDataAbout.json"

const cx = classNames.bind(styles)

const About = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={"About Us"} />
      <main className="w100" style={{ backgroundColor: "#f7f8f9" }}>
        <div className="sectionContainer">
          <div className={cx("aboutFlexContainer")}>
            <section className={cx("aboutSection")}>
              <h3>{AboutJSON.title}</h3>
              {AboutJSON.texts.map((text, i) => (
                <p key={i}>{text.text}</p>
              ))}
            </section>

            <section className={cx("contactSection")}>
              {/* <h3>연락하기</h3>
              <form method="post" action="#" className={cx("contactForm")}>
                <label>
                  Email
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Business email"
                    autoComplete="email"
                  />
                </label>
                <label>
                  Name
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your name"
                  />
                </label>
                <label>
                  Message
                  <textarea
                    name="message"
                    id="message"
                    rows="5"
                    placeholder="Message"
                  />
                </label>
                <button type="submit">보내기</button>
                <input type="reset" />
              </form> */}
            </section>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default About
